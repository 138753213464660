<template>
  <div :class="$style.wrapper">
    <router-link :class="[$style.logoItem, $style.mainLogo]" :to="{ name: correctedRoutePath }">
      <img :class="$style.img" :src="mainLogo || mainLogoDefault" alt="Company" />
    </router-link>
    <div v-if="roomLogo" :class="$style.logoItem">
      <img :class="$style.img" :src="roomLogo" alt="Room" />
    </div>
    <div :class="$style.timers">
      <!-- @TODO improve this feature -->
      <!--      <system-test-->
      <!--        class="nav-top__system-test"-->
      <!--        :broadcast-type-with-speaker="isBroadcastWithSpeaker"-->
      <!--        :min-download-speed="minDownloadSpeedTreshold"-->
      <!--      ></system-test>-->
      <container-broadcast-screen-recorder />
      <clock v-if="clock" />
      <timer v-if="timer" />
    </div>
    <ws-button
      :class="$style.btn"
      :title="$t('Room.title.menu')"
      lg
      icon
      @click="$emit('user-popup-show')"
    >
      <ws-icon xxl light>ellipsis-v</ws-icon>
    </ws-button>
  </div>
</template>

<script>
// import SystemTest from "@/components/common/systemTest/SystemTest";
import mainLogoDefault from "@/assets/images/logo_white.png";
import ContainerBroadcastScreenRecorder from "@/containers/broadcast/ContainerBroadcastScreenRecorder";
import Timer from "@/components/common/timer/Timer";
import Clock from "@/components/common/clock/Clock";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "RoomHeaderTop",
  components: {
    ContainerBroadcastScreenRecorder,
    Timer,
    Clock,
    WsButton,
    WsIcon,
    // SystemTest,
  },
  props: {
    clock: {
      type: Boolean,
      default: false,
    },
    timer: {
      type: Boolean,
      default: false,
    },
    mainLogo: {
      type: String,
      default: "",
    },
    logoRoute: {
      type: String,
      default: "",
    },
    roomLogo: {
      type: String,
      default: "",
    },
    // broadcastType: {
    //   type: String,
    //   default: "",
    // },
  },
  computed: {
    mainLogoDefault() {
      return mainLogoDefault;
    },
    correctedRoutePath() {
      return this.logoRoute.replace(/(-\w)/g, m => m[1].toUpperCase());
    },
    // TODO For speedtest
    // isWebinarType() {
    //   return this.broadcastType === BROADCAST_TYPE.MEETING;
    // },
    // isTypicalType() {
    //   return this.broadcastType === BROADCAST_TYPE.TYPICAL;
    // },
    // isBroadcastWithSpeaker() {
    //   return this.isWebinarType || this.isTypicalType;
    // },
    // minDownloadSpeedTreshold() {
    //   if (this.isWebinarType) {
    //     return 5;
    //   }
    //   if (this.isTypicalType) {
    //     return 2;
    //   }
    //   return 0.3;
    // },
  },
};
</script>

<style lang="scss" module>
.btn {
  height: 100%;
  color: $gray;
  background-color: $gray-lighten;
  border-radius: 0;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.logoItem {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 10px 5px;

  &:first-child {
    min-width: 60px;
    padding-left: 10px;
  }
}

.img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.timers {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
</style>

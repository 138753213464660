<template>
  <div v-if="enabled">
    <ws-button
      outlined
      color="primary"
      md
      :disabled="!onAir || !isRecordingOff"
      @click="startRecord"
    >
      {{ buttonText }}
    </ws-button>
    <broadcast-screen-recorder
      :start="!isRecordingOff && onAir"
      :url-server="urlServerRecording"
      :room="roomNumber"
      @stop="stopEventRecord"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { auth, broadcast, room } from "@/store/modules/store.namespaces";
import { RecordTypes } from "@/constants/broadcast/broadcast-const";
import { IS_BROADCAST_ENABLED, IS_VIDEO_TYPE } from "@/store/modules/broadcast/getter-types";
import { USER_IS_ALLOW_EVENT_RECORD } from "@/store/modules/auth/getter-types";
import WsButton from "@/components/base/WsButton";
import useEventRecording from "@/components/speaker/broadcast/mixins/useEventRecording";

const BroadcastScreenRecorder = () =>
  import(
    /* webpackChunkName:"BroadcastScreenRecorder" */ "@/components/common/broadcast/BroadcastScreenRecorder"
  );

export default {
  name: "ContainerBroadcastScreenRecorder",
  components: {
    BroadcastScreenRecorder,
    WsButton,
  },
  mixins: [useEventRecording],
  data() {
    return {
      urlServerRecording: "wss://fast.whenspeak.ru:8443",
    };
  },
  computed: {
    ...mapState(room, ["roomNumber"]),
    ...mapState(broadcast, ["onAir", "streamServer"]),
    ...mapGetters(broadcast, {
      isVideoType: IS_VIDEO_TYPE,
      isBroadcastEnabled: IS_BROADCAST_ENABLED,
    }),
    ...mapGetters(auth, {
      isAllowEventRecord: USER_IS_ALLOW_EVENT_RECORD,
    }),
    enabled() {
      return (
        this.$hasScreenCapture &&
        this.isAllowEventRecord &&
        this.isBroadcastEnabled &&
        !this.isVideoType
      );
    },
    buttonText() {
      return (
        (!this.isRecordingOff && this.$t("broadcast.recordStarted")) ||
        (this.onAir && this.$t("broadcast.recordStart")) ||
        this.$t("broadcast.streamNotRunning")
      );
    },
  },
  methods: {
    startRecord() {
      this.recordType = this.recordTypes.find(type => type.id === RecordTypes.Event);
      this.serverUrl = this.urlServerRecording;
      this.startEventRecord();
    },
  },
};
</script>

<template>
  <div
    :class="[
      $style.container,
      {
        [$style.container_fixed]: $isSafariIOS12,
      },
    ]"
  >
    <room-header :class="$style.header" />
    <div
      :class="[
        $style.content,
        roomBgClass,
        {
          [$style.content_fixed]: $isSafariIOS12,
        },
      ]"
      :style="roomBgStyle"
    >
      <!-- Место куда перемещается плеер когда мы на мобилке, на десктопе PROVIDER_POSITIONS.ROOM_SIDE -->
      <div
        v-show="!roomPending && onAir && (!$mqLaptop || $fullscreen.isFullscreen)"
        :id="$options.PROVIDER_POSITIONS.ROOM_CONTAINER"
        :class="{
          'visually-hidden': !isPresentationRoute,
        }"
      >
        <!--
          Провайдер плеера рендериться в одном из двух мест:
            на мобилке - PROVIDER_POSITIONS.ROOM_CONTAINER
            на десктопе - PROVIDER_POSITIONS.ROOM_SIDE
        -->
        <container-broadcast-players-provider
          v-if="!roomPending && onAir && !userIsAllowEventRecord"
        />
      </div>
      <router-view :key="$route.fullPath" />
    </div>

    <mobile-rotate-popup v-if="allowShowMobileRotatePopup" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { CUSTOM_THEME } from "@/constants/themes/themes-const";
import { broadcast, room, polls, auth } from "@/store/modules/store.namespaces";
import { TOGGLE_SIDEBAR_FULLSCREEN } from "@/store/modules/common/mutation-types";
import { USER_IS_ALLOW_EVENT_RECORD } from "@/store/modules/auth/getter-types";
import { MqName } from "@/constants/media-query/media-query";
import { interactive } from "@/constants/polls/polls-module-types";
import { PROVIDER_POSITIONS } from "@/constants/broadcast/broadcast-const";
import RoomHeader from "../room/partials/RoomHeader";
import MobileRotatePopup from "./room/MobileRotatePopup";

const ContainerBroadcastPlayersProvider = () =>
  import(
    /* webpackChunkName="PlayersProvider" */ "@/containers/broadcast/ContainerBroadcastPlayersProvider"
  );

export default {
  name: "RoomContainer",
  components: {
    RoomHeader,
    MobileRotatePopup,
    ContainerBroadcastPlayersProvider,
  },
  PROVIDER_POSITIONS,
  computed: {
    ...mapGetters(auth, {
      userIsAllowEventRecord: USER_IS_ALLOW_EVENT_RECORD,
    }),
    ...mapState(polls, ["interactiveTheme", "interactiveThemeBg"]),
    ...mapState(room, ["roomPending", "sidebar"]),
    ...mapState(broadcast, ["onAir"]),
    ...mapState(room, {
      theme: state => state.theme,
    }),
    allowShowMobileRotatePopup() {
      return !this.isPresentationRoute && !this.isInteractiveTest;
    },
    isPresentationRoute() {
      return this.$route.name === "presentation";
    },
    isInteractiveTest() {
      return this.$route.name === "test" && this.$route.query.moduleType === interactive;
    },
    roomBgClass() {
      return this.interactiveTheme ? this.$style[`content__bg_${this.interactiveTheme}`] : "";
    },
    roomBgStyle() {
      return this.interactiveTheme === CUSTOM_THEME
        ? { backgroundImage: `url('${this.interactiveThemeBg}')` }
        : this.roomThemeBgStyle();
    },
  },
  watch: {
    $mq(val) {
      if (val === MqName.MobileL && this.sidebar.fullscreen) {
        this.toggleSidebarFullscreen();
      }
    },
  },
  methods: {
    ...mapMutations(room, {
      toggleSidebarFullscreen: TOGGLE_SIDEBAR_FULLSCREEN,
    }),
    roomThemeBgStyle() {
      if (this.theme.background_url) {
        let styles = [];

        styles.push(`background-image: url('${this.theme.background_url}')`);
        styles.push(`background-position: ${this.theme.background_pos_x} ${this.theme.background_pos_y}`);
        styles.push(`background-size: ${this.theme.background_size}`);

        if (! this.theme.background_repeat) {
          styles.push(`background-repeat: no-repeat`);
        }

        return styles.join(';');
      }

      return '';
    },
  },
};
</script>

<style lang="scss" module>
.container {
  display: grid;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: 120px 1fr;
  overflow: hidden;

  &_interactive {
    grid-template-areas: "content";
    grid-template-rows: 1fr;
  }

  &_fixed {
    position: relative;
  }
}

.header {
  grid-area: header;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: content;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &__bg_dark {
    background-image: linear-gradient(154deg, #4b4b4b, #282828);
  }

  &__bg_custom {
    background: no-repeat center;
    background-size: cover;
  }

  &_fixed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>

var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.container,
    ( _obj = {}, _obj[_vm.$style.container_fixed] = _vm.$isSafariIOS12, _obj ) ]},[_c('room-header',{class:_vm.$style.header}),_c('div',{class:[
      _vm.$style.content,
      _vm.roomBgClass,
      ( _obj$1 = {}, _obj$1[_vm.$style.content_fixed] = _vm.$isSafariIOS12, _obj$1 ) ],style:(_vm.roomBgStyle)},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.roomPending && _vm.onAir && (!_vm.$mqLaptop || _vm.$fullscreen.isFullscreen)),expression:"!roomPending && onAir && (!$mqLaptop || $fullscreen.isFullscreen)"}],class:{
        'visually-hidden': !_vm.isPresentationRoute,
      },attrs:{"id":_vm.$options.PROVIDER_POSITIONS.ROOM_CONTAINER}},[(!_vm.roomPending && _vm.onAir && !_vm.userIsAllowEventRecord)?_c('container-broadcast-players-provider'):_vm._e()],1),_c('router-view',{key:_vm.$route.fullPath})],1),(_vm.allowShowMobileRotatePopup)?_c('mobile-rotate-popup'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
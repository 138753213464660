<template>
  <div ref="time" class="clock icon-clock">
    <div class="clock__time">
      {{ time }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import momentTimezone from "moment-timezone";
import { mapActions, mapMutations, mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";
import { GET_MOSCOW_TIME } from "@/store/modules/common/action-types";
import { SET_NEUROSTATUS } from "@/store/modules/common/mutation-types";

moment.tz = momentTimezone;
const NEURO_PERMISSION_DATE = moment
  .tz(moment(new Date(2018, 5, 19, 21, 0)), "Europe/Moscow")
  .format("x");

export default {
  name: "Clock",
  data() {
    return {
      time: `--:--`,
      clockTimer: null,
      serverClockTimer: null,
      delta: 1,
    };
  },
  computed: {
    ...mapState(room, {
      timestamp: state => state.clock.timestamp || 0,
    }),
    ...mapState(room, ["neuroStatus"]),
  },
  watch: {
    timestamp() {
      this.delta = 0;
    },
  },
  mounted() {
    this.getMoscowTime();
    this.clockTimer = setInterval(this.update, 1000);
  },
  destroyed() {
    clearInterval(this.clockTimer);
  },
  methods: {
    ...mapActions(room, {
      getMoscowTime: GET_MOSCOW_TIME,
    }),
    ...mapMutations(room, {
      setNeurostatus: SET_NEUROSTATUS,
    }),
    getTime() {
      this.delta = this.delta + 1;
      const currentTime = moment((this.timestamp + this.delta) * 1000).tz("Europe/Moscow");
      if (NEURO_PERMISSION_DATE <= currentTime.format("x") && !this.neuroStatus) {
        this.setNeurostatus();
      }

      return currentTime.format("H:mm");
    },
    update() {
      this.time = this.getTime();
    },
  },
};
</script>

<style lang="less" scoped>
.clock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;

  &:before {
    font-size: 18px;
    color: var(--base-color);
  }

  &__time {
    box-sizing: content-box;
    padding-left: 5px;
    color: #0e0e0e;
    font-size: 14px;
    font-family: "ProximaNova", sans-serif;
  }
}
</style>

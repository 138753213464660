<template>
  <header :class="$style.container">
    <room-header-top
      :clock="moscowTime"
      :timer="hasTimer"
      :main-logo="mainLogo"
      :room-logo="roomLogo"
      :logo-route="logoRoute"
      @user-popup-show="toggleUserPopup"
    />
    <layout-navigation>
      <navigation slot="navigation" :class="$style.navigation" :items="navigationItems" />
    </layout-navigation>
  </header>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import {
  NAVIGATION_ELEMENTS,
  MOSCOW_TIME,
  ROOM_TOP_LOGO_SRC,
  ROOM_LOGO,
} from "@/store/modules/common/getter-types";
import { SET_LOGO_ROUTE } from "@/store/mutations/mutation-types";
import { MqName } from "@/constants/media-query/media-query";
import { room } from "@/store/modules/store.namespaces";
import { IGNORED_ROUTES } from "@/constants/router/router-const";
import { TOGGLE_USER_POPUP_VISIBLE } from "@/store/modules/common/mutation-types";
import LayoutNavigation from "@/components/layouts/LayoutNavigation";
import Navigation from "@/components/common/navigation/Navigation";
import RoomHeaderTop from "./RoomHeaderTop";
import { makeUrl } from "@/utils";

const FALLBACK_ROUTE = "presentation";
export default {
  name: "RoomHeader",
  components: {
    RoomHeaderTop,
    LayoutNavigation,
    Navigation,
  },
  computed: {
    // TODO Refactor it after refactor root layouts of view
    ...mapGetters(room, {
      navigationElements: NAVIGATION_ELEMENTS,
      moscowTime: MOSCOW_TIME,
      mainLogo: ROOM_TOP_LOGO_SRC,
      getterRoomLogo: ROOM_LOGO,
    }),
    ...mapState(room, {
      hasTimer: state => !!state.timer.seconds,
    }),
    ...mapState(["logoRoute"]),
    roomLogo() {
      return this.buildLogoImgUrl(this.getterRoomLogo);
    },
    routeName() {
      return this.$route.name;
    },
    navigationItems() {
      return this.navigationElements.filter(item => !item.mq || item.mq.includes(this.$mq)) || [];
    },
  },
  watch: {
    navigationItems(val) {
      if (!val.length) return;
      this.setCorrectRoute();
      const navItem = this.getCorrectNavItemByMq(this.$mq);
      if (navItem && this.logoUrl !== navItem.route) {
        this.setActualLogoUrl();
      }
    },
    $mq(_value, oldValue) {
      this.handleChangeMq(oldValue);
    },
  },
  created() {
    this.setCorrectRoute();
  },
  mounted() {
    this.setActualLogoUrl();
  },
  methods: {
    ...mapMutations({
      setLogoRoute: SET_LOGO_ROUTE,
    }),
    ...mapMutations(room, {
      toggleUserPopup: TOGGLE_USER_POPUP_VISIBLE,
    }),
    buildLogoImgUrl(logo) {
      return logo ? makeUrl(logo) : "";
    },
    setCorrectRoute() {
      const hasCurrentRouteInNavItems = this.checkCurrentRouteInNavItems();
      if (!hasCurrentRouteInNavItems && !IGNORED_ROUTES.includes(this.routeName)) {
        const navItem = this.navigationItems.length && this.getCorrectNavItemByMq(this.$mq);
        this.goToRoutePathFromNavList(navItem);
        this.setActualLogoUrl();
      }
    },
    checkCurrentRouteInNavItems() {
      return this.navigationItems.some(item => item.route === this.routeName);
    },
    goToRoutePathFromNavList(item) {
      const route = item ? item.route : FALLBACK_ROUTE;
      this.$router.push({ name: route });
    },
    setActualLogoUrl() {
      if (this.navigationItems.length) {
        const firstNavItem = this.getCorrectNavItemByMq(this.$mq);
        this.setLogoRoute(firstNavItem.route);
      } else {
        this.setLogoRoute(FALLBACK_ROUTE);
      }
    },
    handleChangeMq(oldMq) {
      const isOldLaptopMq = oldMq === MqName.Laptop;
      if (!this.$mqMobile && !isOldLaptopMq) {
        const currentNavItem = this.getCurrentNavItem();
        const needRedirect =
          this.checkItemMqByOldMq(currentNavItem, oldMq) &&
          !IGNORED_ROUTES.includes(this.routeName);
        if (needRedirect) {
          const correctNavItem = this.getCorrectNavItemByMq(this.$mq);
          this.goToRoutePathFromNavList(correctNavItem);
        }
      }
    },
    getCurrentNavItem() {
      return this.navigationItems.find(item => item.route === this.routeName);
    },
    checkItemMqByOldMq(item, oldValue) {
      return !item || (item.mq && item.mq.includes(oldValue));
    },
    getCorrectNavItemByMq(value) {
      return this.navigationItems.find(item => !item.mq || item.mq.includes(value));
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  flex-shrink: 0;
  width: 100%;
}

.navigation {
  display: flex;
  grid-area: navigation;
  justify-content: center;
}
</style>

<template>
  <div class="mob-rotate-popup" :class="{ 'mob-rotate-popup--shown': $isMobileLandscape }">
    <img class="mob-rotate-popup__pict" src="@/assets/images/phone-rotate.svg" alt="Rotate" />
    <div class="mob-rotate-popup__note">
      <strong class="mob-rotate-popup__gist"> {{ $t("Room.rotateYourMobileVertically") }}, </strong>
      {{ $t("Room.andWorkAgain") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileRotatePopup",
};
</script>

<style scoped lang="less">
.mob-rotate-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5001;
  display: none;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  &__pict {
    width: 140px;
    height: auto;

    @media (max-height: 360px) {
      width: 120px;
    }
  }

  &__note {
    margin-top: 45px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    @media (max-height: 360px) {
      margin-top: 10px;
    }
  }

  &__gist {
    display: block;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
  }

  &--shown {
    display: flex;
  }
}
</style>
